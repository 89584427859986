import { IFlaggedEnum } from '../models/flagged-enum.model';

export module FlaggedEnum {
  'use strict';
  export type IFlaggedEnumGenerator = (
    _enum: any,
    _max: number
  ) => IFlaggedEnum;

  /** create a class definition for a Flagged Enum
   * @method create
   * @param _enum {enum} The enum definition being exteded
   * @param _max {number} the maximum possible value of the enum being extended
   * @returns {IFlaggedEnum} the class definition for the provided enum
   */
  export let create: IFlaggedEnumGenerator = function (
    _enum: any,
    _max: number
  ): IFlaggedEnum {
    const base: any = _enum,
      max: number = _max;

    const Base: IFlaggedEnum = <any>function (val: any): void {
      if (typeof val === 'string') {
        val = base[val];
      }
      this.value = val + 0;
    };

    const proto: any = Base.prototype;

    proto.toString = function (): string {
      const list: string[] = [];
      for (let i = 1; i < max; i = i << 1) {
        if ((this.value & i) !== 0) {
          list.push(' ' + base[i]);
        }
      }
      return list.toString();
    };

    proto.toArrayString = function (): string[] {
      const list: string[] = [];
      for (let i = 1; i < max; i = i << 1) {
        if ((this.value & i) !== 0) {
          list.push(i.toString());
        }
      }
      return list;
    };

    proto.sum = function (val: string[]): number {
      let sum = 0;
      if (val.length > 0) {
        val.forEach((element) => {
          sum += Number(element);
        });
      }
      return sum; 
    };

    return Base;
  };
}
